@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b {
    font-weight: bold!important;
}

code {
  font-family: 'Montserrat', sans-serif;
}

.header {
  overflow: hidden;
  padding: 40px 70px 70px 70px;
  font-size: 19px;
  font-weight: 500;
}
.logo {
  float: left;
  width: 300px;
  height: 134px;
  background: transparent url(/static/media/Simplify_Logo_eCommerce.f1c4d2ec.png) 0% 0%
    no-repeat padding-box;
}
.header .login {
  border: 2px solid #2b9b97;
  color: #2b9b97;
  border-radius: 10px;
  padding: 10px 52px;
  float: left;
  text-transform: uppercase;
  margin-left: 35px;
}
.header .login:hover {
  background-color: #2b9b97;
  color: white;
  cursor: pointer;
}
.header a {
  float: left;
  color: #a8a8a8;
  text-align: center;
  padding: 14px 0;
  margin: 0 24px;
  text-decoration: none;
  line-height: 25px;
  text-transform: uppercase;
  font-weight: 600;
}
.header a:hover {
  border-bottom: 2px dashed #2b9b97;
  color: #2b9b97;
  text-decoration: none;
}
.header a.active {
  border-bottom: 2px dashed #2b9b97;
  color: #2b9b97;
}
.header-right {
  float: right;
  margin: 45px 0;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1382px) {
  .header {
    padding: 70px;
  }
  .header .logo {
    width: 220px;
    height: 100px;
    background-size: 220px 100px;
  }
  .header a {
    margin: 0 22px;
    font-weight: 500;
  }
  .header .login {
    padding: 12px 30px;
    margin-left: 22px;
  }
}

@media only screen and (max-width: 980px) {
  .header .logo {
    width: 100%;
    background-position: center;
    float: none;
    margin-left: 0;
  }
  .header-right {
    float: none;
    margin: 45px 50px;
  }
}
@media screen and (max-width: 700px) {
  .header {
    font-size: 15px;
    padding: 70px 0;
  }
  .header-right {
    float: none;
    margin: 45px auto;
    width: 90%;
  }
  .header a {
    margin: 0 15px;
  }
}

.overlay-content {
  padding: 20px 0 20px 70px;
  overflow: hidden;
}
.background {
  width: 500px;
}
.background svg {
  transform: matrix(0.97, -0.26, 0.26, 0.97, 0, 0);
  opacity: 0.05;
  position: absolute;
  margin: -40px 0px 0px 700px;
}
.title {
  text-align: left;
  letter-spacing: 0;
  color: #021615;
  font-size: 40px;
  font-weight: bold;
  line-height: 50px;
  padding: 0 0 20px 0;
  width: 70%;
}
.first-paragraph {
  text-align: justify;
  font-size: 21px;
  padding: 0 70px 0 0;
  color: #989898;
  letter-spacing: 0.7px;
  line-height: 28px;
}
.first-col {
  width: 54%;
  text-align: justify;
  font-size: 21px;
  padding: 0 0px 10px 0;
  color: #989898;
  float: left;
  letter-spacing: 0.7px;
}
.first-col .paragraph {
  padding-top: 20px;
  line-height: 25px;
}
.first-col .inputGroup {
  box-shadow: 0px 10px 38px #0000001a;
  display: inline-flex;
  margin: 35px 0px 10px 0px;
  width: 80%;
}
.first-col .input-email {
  width: 60%;
  height: 80px;
  border: none;
}
.first-col .input-email::-webkit-input-placeholder {
  /* Edge */
  color: #cbcbcb;
}

.first-col .input-email:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cbcbcb;
}

.first-col .input-email::placeholder {
  color: #cbcbcb;
}

.first-col .input-email[type="text"] {
  font: normal 26px/32px Montserrat;
  padding-left: 36px;
}
.first-col .input-group-append {
  width: 40%;
  height: 80px;
  background: #2b9b97 0% 0% no-repeat padding-box;
  border-radius: 0px 9px 9px 0px;
  padding: 0;
  line-height: 80px;
  text-align: center;
}
.first-col .btn-subcribe {
  margin: auto;
  letter-spacing: 4px;
  font-size: 23px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  padding: 0;
}
.first-col .btn-subcribe:hover {
  cursor: pointer;
}
.first-col .note {
  font-size: 18px;
  margin-top: 15px;
}
.second-col {
  width: 40%;
  height: 400px;
  margin: auto;
  background-image: url(/static/media/lap.492f8e4e.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  -webkit-filter: drop-shadow(10px 10px 10px rgba(244, 247, 249, 1));
          filter: drop-shadow(10px 10px 10px rgba(244, 247, 249, 1));
  opacity: 1;
  float: right;
  margin-right: 60px;
}
@media only screen and (max-width: 1382px) {
  .overlay-content {
    padding: 0;
    font-size: 20px;
  }
  .overlay-content .title {
    font-size: 35px;
  }
  .first-col .note {
    font-size: 18px;
  }
  .first-col .btn-subcribe {
    letter-spacing: 2px;
    font-size: 21px;
  }
  .first-col .input-email[type="text"] {
    font: normal 22px/32px Montserrat;
    padding-left: 25px;
  }
}
@media only screen and (max-width: 980px) {
  .overlay-content .title {
    width: 100%;
    padding: 0 70px 20px 70px;
    font-size: 35px;
  }
  .first-col {
    width: 100%;
    padding: 0 70px 20px 70px;
    float: none;
  }
  .first-col .inputGroup {
    width: 100%;
  }
  .second-col {
    padding-top: 28%;
    height: 0;
    float: none;
  }
}
@media only screen and (max-width: 480px) {
  .background {
    display: none;
  }
  .overlay-content .title {
    width: 100%;
    padding: 0 25px 20px 25px;
    font-size: 25px;
  }
  .first-paragraph {
    padding: 0 5px;
    line-height: 28px;
    font-size: 16px;
  }
  .first-col {
    padding: 5px;
    font-size: 16px;
  }
  .first-col .note {
    font-size: 14px;
    text-align: center;
  }
  .first-col .input-email[type="text"] {
    font: normal 16px/32px Montserrat;
    padding-left: 16px;
  }
  .first-col .btn-subcribe {
    letter-spacing: 0;
    font-size: 18px;
  }
}
/* * {
    box-sizing: border-box;
} */
.pricing {
  padding: 100px 140px;
}
.pricing .background svg {
  transform: matrix(0.96, 0.29, -0.29, 0.96, 0, 0);
  margin: 100px 0px 0px -700px;
}
.pricing .title {
  text-align: center;
  padding: 0;
  font-size: 45px;
  font-weight: 700;
  height: 70px;
  width: 100%;
}
.detail {
  overflow: hidden;
  width: 100%;
}
.columns {
  float: left;
  width: 33.3%;
}
.price {
  list-style-type: none;
  box-shadow: 0px 10px 32px #95959533;
  border-radius: 16px;
  padding-bottom: 20px;
  -webkit-filter: drop-shadow(10px 10px 10px rgba(244, 247, 249, 1));
          filter: drop-shadow(10px 10px 10px rgba(244, 247, 249, 1));
  background-color: white;
  margin: 30px;
}
.price .header {
  text-align: center;
  letter-spacing: 0;
  color: #2b9b97;
  padding: 50px 0 0 0;
  font-size: 35px;
  font-weight: 700;
}
.price .price-detail {
  text-align: center;
  font-size: 25px;
  padding: 0 0 20px 0;
  text-align: center;
  letter-spacing: 0;
  color: #142535;
  font-weight: 600;
}
.price .price-detail .sign {
  position: absolute;
  font-size: 40px;
  font-weight: 800;
}

.price .price-detail .number {
  font: Bold 100px/100px Montserrat;
  margin-left: 35px;
}

.price .description {
  text-align: center;
  color: #989898;
  font-size: 24px;
  font-weight: 500;
}

.price ul {
  list-style: none;
  padding: 30px 20px 10px 70px;
  height: 255px;
}
.price li:before {
  content: "\2713\0020";
  font-size: 27px;
  padding: 0 4px;
  font-weight: 600;
  color: #2b9b97;
  margin-left: -40px;
}
.price li {
  color: #142535;
  font-size: 20px;
  line-height: 25px;
}
.price li .bold {
  font-weight: 700;
}
.price-button {
  text-align: center;
  background: #2b9b97 0% 0% no-repeat padding-box;
  border-radius: 9px;
  margin: 20px 40px;
  padding: 20px;
  letter-spacing: 4px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 700;
  cursor: pointer;
}
.pricing .note {
  text-align: center;
  font-size: 24px;
  color: #989898;
  margin-top: -15px;
}
@media only screen and (max-width: 1600px) {
  .price .description {
    height: 60px;
  }
  .price ul {
    height: 320px;
  }
}
@media only screen and (max-width: 1382px) {
  .pricing {
    padding: 100px 70px;
  }
  .columns {
    padding: 0;
  }
  .pricing .note {
    font-size: 20px;
    margin-top: 0;
  }
}
@media only screen and (max-width: 1070px) {
  .columns {
    width: 100%;
  }
  .price ul {
    height: auto;
  }
  .price .description {
    height: auto;
  }
}
@media only screen and (max-width: 480px) {
  .pricing {
    padding: 100px 0;
  }
  .pricing .title {
    font-size: 35px;
  }
  .pricing .header {
    font-size: 25px;
  }
  .price .price-detail {
    font-size: 20px;
  }
  .price .price-detail .sign {
    font-size: 35px;
  }
  .price .price-detail .number {
    font-size: 80px;
  }
  .pricing .description {
    font-size: 18px;
  }
  .pricing li {
    font-size: 16px;
  }
  .price li:before {
    font-size: 22px;
  }
  .price-button {
    letter-spacing: 2px;
    font-size: 17px;
  }
  .pricing .note {
    font-size: 14px;
  }
}

.app-integrations {
  padding-bottom: 100px;
}
.app-integrations .background svg {
  margin: 100px 0px 0px 720px;
}
.app-integrations .title {
  text-align: center;
  padding: 50px 0;
  font-size: 45px;
  font-weight: 700;
  width: 100%;
}
.app-integrations .app-detail .partner-group {
  overflow: hidden;
}
.app-integrations .app-detail .partner-group:nth-child(2) .partner {
  margin-right: -100px;
}
.app-integrations .app-detail .partner-group .partner-info {
  float: left;
  width: 45%;
  padding: 30px 0 0 140px;
}
.app-integrations .app-detail .partner-group .top-label {
  letter-spacing: 0.5px;
  color: #2b9b97;
  font-size: 34px;
  font-weight: 500;
}
.app-integrations .app-detail .partner-group .description {
  text-align: left;
  color: #989898;
  font-size: 20px;
  padding-top: 10px;
  letter-spacing: 0.1px;
}
.app-integrations .app-detail .partner-group .partner {
  float: right;
  width: 55%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 310px;
  padding-right: 100px;
}
.app-integrations .app-detail .partner-group .partner .hex-logo {
  float: left;
  position: relative;
}
.app-integrations .app-detail .partner-group .partner .hex-logo:nth-child(1) {
  margin-left: 0;
}
.app-integrations .app-detail .partner-group .partner .hex-logo .img-fluid {
  width: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.app-integrations .note {
  text-align: center;
  font-size: 24px;
  color: #989898;
  margin: 30px 0;
}
/* hexagon container */
.hexagon {
  position: relative;
  width: 250px;
  height: 144.34px;
  background-color: white;
  margin: 72.17px 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 18px;
}
.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 176.78px;
  height: 176.78px;
  transform: scaleY(0.5774) rotate(-45deg);
  background-color: inherit;
  left: 36.6117px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 18px;
}
.hexagon:before {
  top: -80.3883px;
}
.hexagon:after {
  bottom: -88.3883px;
}
/*cover up extra shadows*/
.hexagon span {
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  width: 250px;
  height: 151.3376px;
  z-index: 2;
  background: inherit;
  border-radius: 18px;
}
@media only screen and (max-width: 1382px) {
  .app-integrations .app-detail .partner-group:nth-child(2) .partner {
    padding-left: 70px;
  }
  .app-integrations .note {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1070px) {
  .app-integrations .background svg {
    margin: -27px 0px 0px 520px;
  }
  .app-integrations .app-detail .partner-group .partner-info {
    float: none;
    width: 100%;
    padding: 30px 70px 0;
  }
  .app-integrations .app-detail .partner-group .partner {
    float: none;
    width: 100%;
    padding-left: 70px;
  }
  .app-integrations .note {
    font-size: 18px;
  }
}
@media only screen and (max-width: 650px) {
  .hexagon {
    width: 200px;
    height: 109.47px;
    margin: 57.74px 0;
  }
  .hexagon:before,
  .hexagon:after {
    width: 151.42px;
    height: 151.42px;
    left: 24.2893px;
  }
  .hexagon:before {
    top: -65.7107px;
  }
  .hexagon:after {
    bottom: -70.7107px;
  }
  /*cover up extra shadows*/
  .hexagon span {
    width: 200px;
    height: 115.4701px;
  }
  .app-integrations .app-detail .partner-group .partner .hex-logo .img-fluid {
    width: 110px;
  }
  .app-integrations .app-detail .partner-group .partner {
    padding-left: 0;
    height: 250px;
  }
  .app-integrations .app-detail .partner-group:nth-child(2) .partner {
    padding-left: 0;
  }
  .app-integrations .app-detail .partner-group .top-label {
    font-size: 28px;
  }
  .app-integrations .app-detail .partner-group .description {
    font-size: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .app-integrations .title {
    font-size: 35px;
  }
  .app-integrations .app-detail .partner-group .top-label {
    font-size: 22px;
  }
  .app-integrations .app-detail .partner-group .description {
    font-size: 17px;
  }
  .app-integrations .note {
    font-size: 18px;
  }
  .app-integrations .app-detail .partner-group .partner-info {
    padding: 5px;
  }
  .app-integrations .app-detail .partner-group .partner {
    height: 200px;
  }
  .hexagon {
    width: 150px;
    height: 82.6px;
    margin: 43.3px 0;
  }
  .hexagon:before,
  .hexagon:after {
    width: 106.07px;
    height: 106.07px;
    left: 21.967px;
  }
  .hexagon:before {
    top: -47.033px;
  }
  .hexagon:after {
    bottom: -50.033px;
  }
  /*cover up extra shadows*/
  .hexagon span {
    width: 150px;
    height: 86.6025px;
  }
  .app-integrations .app-detail .partner-group .partner .hex-logo .img-fluid {
    width: 80px;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 0 auto;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.footer .background{
    height: 225px;
    width: 100%;
    background-color: #003836;
}
.footer .info{
    background-color: #101D2A;
    color: #989898;
    overflow: hidden;
    padding: 20px;
    letter-spacing: 0.62px;
}
.footer .info .left-link{
    float: left;
    width: 34%;
    margin-left: 60px;
}
.footer .info .left-link a{
    margin: 0 20px;
    color: #989898;
    font-size: 16px;
    text-decoration: none;
}
.footer .info .right-info{
    float: right;
    font-size: 18px;
    margin-right: 60px;
}
.footer .info .right-info a{
  color: #989898;
  font-size: 18px;
  text-decoration: none;
}
@media only screen and (max-width: 1382px) {
    .footer .info{
        text-align: center;
    }
    .footer .info .left-link{
        float: none;
        width: 100%;
        margin: 0px 0 10px 0;
    }
    .footer .info .right-info{
        width: 100%;
        float: none;
    }
}
@media screen and (max-width: 768px) {
    .footer .info .left-link a{
        font-size: 14px;
        margin: 0 10px;
    }
    .footer .info .right-info{
        font-size: 14px;
    }
}

@media screen and (max-width: 480px) {
    .footer .info .left-link a{
        font-size: 12px;
        margin: 0 8px;
    }
    .footer .info .right-info{
        font-size: 12px;
    }
}
